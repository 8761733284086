import React, { useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { AuthContext } from 'context/AuthProvider';
import {  FaPowerOff } from 'react-icons/fa';
import { upperFirst } from 'lodash';

export default function ProfileMenu({ isMobile }) {
  let navigate = useNavigate();
  const { logOut, user } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logOut();
    localStorage.removeItem('filtre');
    navigate('/', { replace: true });
  }

  const menuItems = [];
  if(user?.nom && !isMobile) {
    menuItems.push({ label:<span className='login-name' >{upperFirst(user?.nom)} {user?.prenoms}</span>, key: 'name' });
    menuItems.push({ label:<span  style={{ cursor:"none"}}><hr></hr> </span>, key: 'ligne' });
  }

  menuItems.push({icon:<FaPowerOff style={{marginLeft:"10px"}} className='logout'/>,label:<span className='login-deconnexion'  onClick={handleLogout}>Déconnexion</span>, key: 'log_out' });
  

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      { !isMobile &&
        <div style={{marginLeft:"15px"}} className="dropdown-handler" onClick={handleDropdown}>
          <FaPowerOff></FaPowerOff>
        </div>
        }
      <Menu
        className={`dropdown-menu ${state ? 'active' : 'hide'}`}
        items={menuItems}
        onClick={closeDropdown}
      />
    </div>
  );
}
