import React , { useState  }from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import {Modal } from 'antd';
import { LISTING_POSTS_PAGE } from 'settings/constant';
import useMoreDataApi from 'library/hooks/useMoreDataApi';
import { NavbarSearchWrapper } from './Header.style';
import { TITRE_LISTE_HOTEL } from 'library/helpers/constUtils';

export default function NavbarSearch() {
  let navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let villeValue = urlParams.get('ville');
  
  const [city, setCity] = useState(null)

  const customNoOptionsMessage = () => "Aucune option";
  let url = [
    `${process.env.REACT_APP_API_URL}/villes`
    ] ;
  let { data, loading, error, errorMessage } = useMoreDataApi(url);

  const modalError = (title, message) => {
    Modal.error({
      title: title,
      content: message,
      onOk() {
        return;
      }
    });
  };

  if (error){
    modalError(TITRE_LISTE_HOTEL, 'Ooups! Une erreur est survenue');
    return ; 
  }
  if (data && (data.length== 0 || loading)) { 
    return ;
  }

  let ville = {} ; 
  data.map(
    ({
      key,
      value
    }) => {
      switch (key) {
        case url[0]:
          ville = value ; 
          break;
        default:
          break;
      }
    })


  let adaptedOptions = ville.map(option => ({
    value: option.id,
    label: option.nomVille
  }));


  let defaultValue = null ;
  if (villeValue && !city){
    defaultValue = adaptedOptions.find(option => option.value === parseInt(villeValue))
    setCity(defaultValue);
  }


  const handleChange = (e) => {
    let path = "" ; 
    if (e)
    {
    path = `${LISTING_POSTS_PAGE}?ville=${e.value}` ;
      setCity({
        label: (e.label),
        value: (e.value),
      });
    }else{
      setCity({
        label: null,
        value: null,
      });
      path=LISTING_POSTS_PAGE ;
    }
    console.log(path)
    navigate(path);
    
    window.location.reload(false);
    
    // setFilter(param);
    // saveState("filtre", param);
    // navigate({
    //     pathname: LISTING_POSTS_PAGE + `?ville=${value}`})
  }

  return (
    <NavbarSearchWrapper className="navbar_search">
    <Select isClearable className = "select-search" options={adaptedOptions} 
                  onChange={handleChange} defaultValue ={city} placeholder="Où ?" 
                  noOptionsMessage={customNoOptionsMessage} />
                  
      
    </NavbarSearchWrapper>
  );
}
