import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveState, loadState } from 'library/helpers/localStorage';
import { ADMIN_PARTENAIRE_PAGE, SUPER_ADMIN_LIST_DOSSIER } from 'settings/constant';

export const AuthContext = React.createContext();

const isLoggedIn = ()=> {
  return loadState('user')?true:false;
}

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [user, setUser] = useState(loadState('user'));

  const signInPartenaire= () => {
    navigate("/", { replace: true });
  }

  const signInClient= () => {
    navigate("/sign-in", { replace: true });
  }

  const signInOtheUser = () => {
    navigate("/sign-in", {state :{type:"utilisateur"}}); 
  }

  const signIn = (params) => {
    console.log(params, 'sign in form Props');
    setUser(params);
    saveState('user',params);
    setLoggedIn(true);
    redirection(params);
   
  };

  const signUp = (params) => {
    console.log(params, 'sign up form Props');
    setUser(params);
    saveState('user',params);
    setLoggedIn(true);
    redirection(params);
  };

  const  redirection = (user)=> {

    if(user.roles.includes('ROLE_SUPER_ADMIN')
    || user.roles.includes('ROLE_MANAGER')
    || user.roles.includes('ROLE_AGENT_COM')) {
      navigate({pathname: SUPER_ADMIN_LIST_DOSSIER}); ;
    }
    else if(user.roles.includes('ROLE_ADMIN_HOTEL')
    || user.roles.includes('ROLE_RECEP')) {
      navigate({pathname: ADMIN_PARTENAIRE_PAGE});
    } else {
      navigate({pathname: "/"});
    }
  }

  const logOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    setLoggedIn(false);
  };
 
  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        signInPartenaire,
        signInClient,
        signInOtheUser,
        user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
