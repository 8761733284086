
import { useState, useReducer, useEffect,useContext } from 'react';
import {loadState} from 'library/helpers/localStorage' ;
import { AuthContext } from 'context/AuthProvider';


async function SuperFetch(url, method = 'GET', headers = {"Content-Type": "application/json"}, body = {}) {

  let options = {
    method,
    headers,
  };
  if (method === 'POST' || method === 'PUT') options = { ...options, body };

  try {
    const response = await fetch(url, options);
    return response.json();
  } catch (error) {
    throw new Error('Erreur lors de la récupération des données : ' + error.message);
  }
}

function dataFetchReducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case 'LOAD_MORE':
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      throw new Error();
  }
}

const useMoreDataApi = (initialUrl,initialMethod=null, initialBody=null,initialHeader=null, initialData = []) => {
  const [url, setUrl] = useState(initialUrl);
  const [methodInit, setMethod] = useState(initialMethod);
  const [bodyInit, setBody] = useState(initialBody);
  const [headerInit, setHeader] = useState(initialHeader);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    errorMessage: '',
    data: initialData,
  });

  useEffect(() => {
    let didCancel = false;


    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
    
      if (url.length > 0) {
        try {
          let hasError = false; // Ajout d'une variable pour suivre l'existence d'une erreur
          let message = "" ;
    
          const promises = url.map(async (u,i) => {
            try {
              let methodTmp = (methodInit && methodInit[i]) ? methodInit[i] : 'GET';
              let bodyTmp = (bodyInit && bodyInit[i]) ? bodyInit[i] : {};
              let headerTmp = (headerInit && headerInit[i]) ? headerInit[i] : {"Content-Type": "application/json"} ;
              const result = await SuperFetch(u,methodTmp,headerTmp,bodyTmp)
              .then(res => {
                if (res.error  || res.status ) {
                  hasError = true;
                  message += res.message ;  
                }
                return res ; 
              })
              return { key: u, value: result };
            } catch (error) {
              hasError = true; // Une erreur s'est produite
              return { key: u, value: null }; 
            }
          });
          const results = await Promise.all(promises)
    
          if (!didCancel && !hasError) { // Vérification si une erreur s'est produite avant de mettre à jour l'état
            dispatch({ type: 'FETCH_SUCCESS', payload: results });
          } else if (!didCancel && hasError) {
            dispatch({ type: 'FETCH_FAILURE', payload: message });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE', payload: error.message });
          }
        }
      }
    };

    // const fetchData = async () => {
    //   dispatch({ type: 'FETCH_INIT' });

    //   if (url.length > 0) {
    //     try {
    //       const promises = url.map(u => SuperFetch(u));
    //       const results = await Promise.all(promises);

    //       console.log("results",results);

    //       if (!didCancel) {
    //         const data = url.map((u, index) => ({ key: u, value: results[index] }));
    //         dispatch({ type: 'FETCH_SUCCESS', payload: data });
    //       }
    //     } catch (error) {
    //       console.log("error",error);
    //       if (!didCancel) {
    //         dispatch({ type: 'FETCH_FAILURE', payload: error.message });
    //       }
    //     }
    //   }
    // };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [url]);

  const loadMoreData = () => {
    dispatch({ type: 'LOAD_MORE' });
  };

  const doFetch = (urls) => {
    if (JSON.stringify(urls) !== JSON.stringify(url)) {
      setUrl(url);
    }
  };

  // const doFetch = (urls) => {
  //   setUrl(urls);
  // };

  return { ...state, doFetch,loadMoreData };
};

export default useMoreDataApi;
