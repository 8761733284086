import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const LogoArea = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  img {
    width: 20px;
  }

  h3 {
    color: ${themeGet('primary.0', '#008489')};
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 0 10px;
  }
  h5{
    font-family : 'Dancing Script, cursive';
    color : white;
  }
`;

export default LogoArea;

export const TitleSlogan = styled.div`
  font-family: "Lobster Two", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 23px;
  text-align:center;
`;
