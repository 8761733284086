import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import RadioGroup from 'components/UI/RadioGroup/RadioGroup';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  CONTACT_PAGE,
  LIST_RESERVATION
} from 'settings/constant';
import { getLocalCurrency, setLocalCurrency } from 'context/currency';
import { DEFAULT_DEVISE } from 'library/helpers/constUtils';
import MenuMobileContainer from './MobileMenu.style';

const MobileMenu = ({ className }) => {

  const [defaultCurrency] = useState(getLocalCurrency) ; 

  useEffect(()=> {
    if (!defaultCurrency) {
      setLocalCurrency(DEFAULT_DEVISE);
    }
  }, defaultCurrency)

  const handleMobileDevise = (e) => {
    setLocalCurrency(e.target.value);
    window.dispatchEvent(new Event("storage"));
  }

  // auth context
  const { loggedIn, logOut, user } = useContext(AuthContext);

  let navigations = [
    {
      label: <NavLink to={HOME_PAGE}>Accueil</NavLink>,
      key: 'hotels',
    },
    {
      label: <NavLink to={LISTING_POSTS_PAGE}>Hôtels</NavLink>,
      key: 'listing',
    },
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: 'pricing',
    },
    {
      label:  
      //<label for="radio1">Books</label>
      <RadioGroup
      style={{"width" : "100px"}}
      onChange={handleMobileDevise}
      defaultValue={defaultCurrency}
      buttonStyle="solid"
      options={[
        { label: '€', value: '€' },
        { label: 'Ar', value: 'Ar' }
      ]}
      className='btnMobileSwitchDevise'
      />
     ,
      key: 'menu-4'
    } ,
    // {
    //   label: loggedIn && (
    //     <NavLink to={`${AGENT_ACCOUNT_SETTINGS_PAGE}`}>
    //       Paramètre du compte
    //     </NavLink>
    //   ),
    //   key: 'account_settings',
    // },
    {
      label: loggedIn && <button onClick={logOut}>Déconnecter</button>,
      key: 'logout',
    },
  ];

  let withReservation = [
    {
      label: <NavLink to={HOME_PAGE}>Accueil</NavLink>,
      key: 'hotels',
    },
    {
      label: <NavLink to={LISTING_POSTS_PAGE}>Hôtels</NavLink>,
      key: 'listing',
    },
    {
      label: <NavLink to={LIST_RESERVATION}>Réservation</NavLink>,
      key: 'reservation',
    },
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: 'pricing',
    },
    {
      label:  
      //<label for="radio1">Books</label>
      <RadioGroup
      style={{"width" : "100px"}}
      onChange={handleMobileDevise}
      defaultValue={defaultCurrency}
      buttonStyle="solid"
      options={[
        { label: '€', value: '€' },
        { label: 'Ar', value: 'Ar' }
      ]}
      className='btnMobileSwitchDevise'
      />
     ,
      key: 'menu-4'
    } ,
    // {
    //   label: loggedIn && (
    //     <NavLink to={`${AGENT_ACCOUNT_SETTINGS_PAGE}`}>
    //       Paramètre du compte
    //     </NavLink>
    //   ),
    //   key: 'account_settings',
    // },
    {
      label: loggedIn && <button onClick={logOut}>Déconnecter</button>,
      key: 'logout',
    },
  ];

  if (user?.roles && user.roles.includes('ROLE_CLIENT')){
    navigations = withReservation ; 
  }

  return <MenuMobileContainer><Menu className={className} items={navigations} /></MenuMobileContainer>;
};

export default MobileMenu;
