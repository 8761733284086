import { DEFAULT_DEVISE } from "library/helpers/constUtils";

export const getLocalCurrency = () => {
    return localStorage.getItem("currency") || DEFAULT_DEVISE;
  };
  
export const setLocalCurrency = (currency) => {
localStorage.setItem("currency", currency);
};

