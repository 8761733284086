import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet('primary.0', '#008489')};
    color: ${themeGet('color.1', '#ffffff')};
  }

  //todo to delete when solution is found hide 
  //random error => loop completed with undelivered notifications
  iframe#webpack-dev-server-client-overlay{display:none!important}

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet('color.1', '#ffffff')};

    main.ant-layout-content {
      flex: 1 0 auto;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet('text.0', '#110044')};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet('text.2', '#777777')};
        justify-content: flex-start;
        strong {
          color: ${themeGet('text.0', '#110044')};
          width: 30%;
        }
      }
    }
  }

  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#110044')};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#110044')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#008489')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#110044')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#110044')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-dropdown-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-dropdown-menu-item {
          height: inherit;
          line-height: inherit;

          .ant-dropdown-menu-title-content {
            display: block;
          }

          div {
            padding: 5px 4px;
            color: ${themeGet('text.0', '#110044')};

            svg {
              margin-right: 8px;
            }

            &:hover {
              background-color: ${themeGet('color.2', '#F7F7F7')};
            }
          }
        }
      }
    }
  }

  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 99999 !important;
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media   (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media   (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media   (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media   (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media   (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }

    span {
      margin: 0 !important;
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media   (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media   (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media   (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet('color.1', '#ffffff')};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet('primary.0', '#008489')};
      svg {
        fill: ${themeGet('primary.0', '#008489')};
      }
      &:hover {
        background-color: ${themeGet('primary.0', '#008489')};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet('text.0', '#110044')};
    }
  }

  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        margin: 0 0 30px;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet('color.2', '#F7F7F7')};
        li {
          margin-right : 15px;
          height: auto;
          line-height: 1;
          padding: 0;
          overflow: inherit;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 700;
            color: ${themeGet('text.0', '#110044')};
          }
          &:last-child {
            a {
              color: ${themeGet('color.1', '#ffffff')};
              background-color: ${themeGet('primary.0', '#008489')};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .currency-menu{
        border: 0;
        margin-left : 50px;
        background-color: #0000000;

        
      }
      .main-menu {
        border: 0;
        padding-top: 30px;
        li {
          
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            font-size: 15px;
            padding: 0 31px;
            border-left: 4px solid transparent;
            color: ${themeGet('text.0', '#110044')};
          }
          &.ant-menu-item-selected a.active {
            font-weight: 700;
            border-color: ${themeGet('primary.0', '#008489')};
            color: ${themeGet('primary.0', '#008489')};
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 32px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet('text.0', '#110044')};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
              color: ${themeGet('primary.0', '#008489')};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet('primary.0', '#008489')};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media   (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet('text.1', '#909090')};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet('text.0', '#110044')};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet('color.0', '#000000')};
        }
      } 
      &:hover {
        background-color: ${themeGet('color.2', '#F7F7F7')};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 50px;
            padding-bottom: 20px;
            border-color: ${themeGet('border.0', '#EBEBEB')};
            @media   (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet('border.0', '#EBEBEB')};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet('text.1', '#909090')};
          transition: all 0.3s ease;
          @media   (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('text.0', '#110044')};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet('color.1', '#ffffff')};
    header {
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media   (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet('text.0', '#110044')};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet('primary.0', '#008489')};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color: #008489d1;
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media   (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 3px;
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }

  .ant-input-search-icon::before {
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${themeGet('primary.0', '#008489')} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 132, 137, 0.2);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${themeGet('border.3', '#E6E6E6')};
  }

  .ant-radio-group-solid {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: transparent;
      background-color: ${themeGet('primary.0', '#008489')};
      &::before {
        content: none;
      }
      &:hover {
        border-color: transparent;
        color: #fff;
        background-color: ${themeGet('primary.0', '#008489')};
        &::before {
          content: none;
        }
      }
    }
    .ant-radio-button-wrapper {
      border-color: transparent;
      background : none ;
      &:hover {
        color: ${themeGet('primary.0', '#008489')};
      }
    }
  }


  .DraftEditor-editorContainer {
    min-height: 150px !important;
  }

  .ant-select-selector {
    min-height:48px;
    width:100%;
  }

  .public-DraftStyleDefault-block {
    line-height: 26px;
  }

  .ant-btn-primary {
    margin-top: 2px;
    margin-left : 2px;
    border-radius: 3px;
    border-color: ${themeGet('primary.0', '#008489')} !important;
    background-color: ${themeGet('primary.0', '#008489')} !important;
    &::after {
      content: none;
    }
  }

  .btn-icon {
    padding-top: 2px;
    margin-right : 2px
  }

  #tab_chambre_hebergement .form-control{
    margin-bottom : 10px;
  }

  td .form-control{
    margin-bottom : inherit;
  }
 
  .ant-table-wrapper .ant-table-tbody>tr>td {
    padding : 6px
  }

  .ant-checkbox-wrapper .ant-checkbox + span {
    font-weight : inherit;
  }

  .ant-menu-submenu a {
    color:inherit;
  }
  .ant-menu-submenu a.active, .ant-col .ant-menu-item a.active{
    color : #19AB6E !important;
  }

  .ant-select-item-option-state,
  .ant-tabs-tab-active div.ant-tabs-tab-btn, 
  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active,
  .ant-checkbox-inner,
  .ant-table-filter-trigger.active,
  .ant-btn-link,
  .anticon-search:hover,
  .anticon-search:focus,
  .ant-tabs-tab:hover,
  .ant-pagination-item-active a 
  {
    color: #19AB6E !important;
  }

  .ant-select-selector:hover,
  .ant-select-selector:focus,
  .ant-pagination .ant-pagination-item-active:hover, 
  .ant-pagination-item-active,
  .ant-select:hover, ant-select-focus,
  .ant-input-search .ant-input:focus,.ant-input:hover,
  .ant-input-search-button:hover,.ant-input-search-button:focus{
    border-color: #19AB6E !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #19AB6E !important;
    border-color:#19AB6E !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid #19AB6E !important;
    z-index: 2;
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    font-weight: 700;
  }

  .btn-delete{
    color:#FFFFFF!important;
    border-color:#FF0000!important;
    background-color: ${themeGet('danger.0', '#FF0000')} !important;
  }

  .btn-delete-griser{
    color:#FFFFFF!important;
    border-color:#D3D3D3!important;
    background-color: ${themeGet('danger.0', '#D3D3D3')} !important;
  }

  .table-row-search {
    margin-bottom:10px;
    display:flex;
    justify-content: space-between;
    @media   (max-width: 375px) {
      div {
        margin-top:10px;
      }
    }
  }

  .ant-upload-list-item-done a {
    color: #19AB6E !important;
  }

  .ant-btn-default:hover {
    background-color: #19AB6E!important;
    border-color: #19AB6E!important;
    color:#ffffff!important;
  }

  .ant-spin-dot-item {
    background-color: #19AB6E!important;
  }
  .table-overflow-x {
    overflow-x:auto;
  }
  .ant-pagination-options{
    display:none!important;
  }
  .ant-notification {
    z-index : 99999!important;
  } 

  .nowrapCell {
    white-space : nowrap;
  }

  .check-inactif{
    color:#FF0000!important;
  }

  .icone-action{
    margin-left: 5px;
  }
  .table-row-search h2{
    display: block!important;
  }
  .avatar-dropdown {
    color:#FFFFFF;
  }
  .isHeaderSticky .avatar-dropdown, .is_default .avatar-dropdown {
    color:#000000;
  }
  .isHeaderSticky .avatar-dropdown, .is_default .avatar-dropdown  {
    &:hover {
      color: #19AB6E!important;
    }
  }

  .login-deconnexion {
    &:hover {
      color: #19AB6E!important;
    }
  }

  .login-name {
    cursor:none; 
    padding-left:10px !important;
    padding-right:10px !important;
    padding-top:10px !important;
  }

  .avatar-dropdown ul {
    padding : 10px;
  }
  .ant-select-selection-placeholder {
    padding : 5px !important;
  }

  .colonne-action {
    display : flex;
    flex-direction : row;
  }

  .SingleDatePicker button:hover{
     background-color: #19AB6E !important;
    .SingleDatePickerInput_clearDate_svg {
      fill:#fff!important;
    }
  }
  .SingleDatePickerInput_clearDate_svg{
    fill:#19AB6E!important;
  }
  .price-gras span {
  font-weight: 700;
  }
`;

export default GlobalStyle;