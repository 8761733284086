// localStorage.js
import moment from 'moment';

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    if (key == 'filtre'){
      var filtreParam = JSON.parse(serializedState);
      if (filtreParam?.date_range){
        var isAfter = false ; 
        //si date du jour est apres la date du filtre. 
        if (moment().startOf('day').
          isAfter(moment(filtreParam.date_range.setStartDate, 'DD-MM-YYYY')) || moment().startOf('day').
          isAfter(moment(filtreParam.date_range.setEndDate, 'DD-MM-YYYY'))){
            isAfter = true;
        }
        if (isAfter){
          filtreParam.date_range = {
            setEndDate : null,
            setStartDate : null
          }
        }
      }
      return filtreParam ;
    }else{
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};
