import themeGet from "@styled-system/theme-get";

const { default: styled } = require("styled-components");

const MenuMobileContainer = styled.div`
  .btnMobileSwitchDevise label{
    border: 1px solid ${themeGet('primary.0', '#008489')};
  }
  .btnSwitchDevise label:not(:first-child)::before {
    background-color: ${themeGet('primary.0', '#008489')};
  }
`;

export default MenuMobileContainer;