import React from 'react';
import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';
import FooterMenu from './FooterMenu';
import { NOM_DOMAINE } from 'settings/constant';

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo="/"
          src="/images/logo-alt.png"
          title={NOM_DOMAINE}
        />
      }
      menu={<FooterMenu />}
      // admin ={"test"}
      copyright={`Copyright @ ${new Date().getFullYear()} ${NOM_DOMAINE}`}
    />
  );
};

export default Footer;
