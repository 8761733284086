import themeGet from "@styled-system/theme-get";

const { default: styled } = require("styled-components");

const MenuContainer = styled.div`
  .btnSwitchDevise label{
    border: 1px solid ${themeGet('primary.0', '#008489')};
  }

  .btnSwitchDevise label:not(:first-child)::before {
    background-color: ${themeGet('primary.0', '#008489')};
  }

  .obtenirApp {
    width: inherit !important;
  }

  .spanDowloadLink {
    border-radius:30px; 
    border: solid 2px; 
    padding:5px 15px;
  }
  
`;

export default MenuContainer;