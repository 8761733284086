
import React, { useContext } from 'react';
import { NavLink} from 'react-router-dom';
import { Menu} from 'antd';
import { FaHotel, FaFolder, FaUser, FaTools,FaHistory, FaCity, FaServicestack, FaBookmark, FaBus, FaMoneyCheck, FaGift } from 'react-icons/fa';
import {
    SUPER_ADMIN_LIST_DOSSIER,
    SUPER_ADMIN_LIST_HOTEL,
    SUPER_ADMIN_LIST_USER,
    SUPER_ADMIN_PARAMETRE,
    SUPER_ADMIN_TRACE_UTILISATION,
    SUPER_ADMIN_VILLE,
    SUPER_ADMIN_EQUIPEMENT,
    SUPER_ADMIN_CATEGORIE_HOTEL,
    SUPER_ADMIN_CATEGORIE_EQUIPEMENT,
    SUPER_ADMIN_RESERVATIONS,
    SUPER_ADMIN_CHAMBRE_CATEGORIE_EQUIPEMENT,
    SUPER_ADMIN_CHAMBRE_EQUIPEMENT,
    SUPER_ADMIN_SERVICE,
    SUPER_ADMIN_CATEGORIE_SERVICE,
    SUPER_ADMIN_CHAMBRE_SERVICE,
    SUPER_ADMIN_CHAMBRE_CATEGORIE_SERVICE,
    SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT,
    SUPER_ADMIN_ACCESSIBILITE,
    SUPER_ADMIN_DEVISE,
    SUPER_ADMIN_HOTEL_PROMOTION
} from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';

  
export  function SuperAdminSideMenu () {
  const {user} = useContext(AuthContext);
  let menusHotel =[
    {
      label: <NavLink to={SUPER_ADMIN_LIST_HOTEL}>Hôtels</NavLink>,
      key: 'hotel',
      icon: <FaHotel className='Hotel' />
    },
    {
        label: <NavLink to={SUPER_ADMIN_CATEGORIE_HOTEL}>Catégories hôtels</NavLink>,
        key: 'categorie_hotel',
        icon: <FaHotel className='Categorie' />
    }
  ];

  if((user.roles.includes('ROLE_SUPER_ADMIN')
    || user.roles.includes('ROLE_ADMIN_HOTEL')) && user?.idHotel>0) {
    menusHotel.push(
      {
        label: <NavLink to={SUPER_ADMIN_HOTEL_PROMOTION}>Offres du moment</NavLink>,
        key: 'promotion',
        icon:<FaGift className='Promotion'/>
      }
    );
  }

  menusHotel.push(
    {
      label: <NavLink to={SUPER_ADMIN_EQUIPEMENT} title='Catégories équipements hébergements'>Equipements hébergements</NavLink>,
      key: 'categorie_equipement',
      icon:<FaTools className='CategorieEquipement'/>,
      children : [
        {
          label: <NavLink to={SUPER_ADMIN_CATEGORIE_EQUIPEMENT} title='Catégories équipements hébergements'>Catégories</NavLink>,
          key: 'categorie_equipement',
          icon:<FaTools className='CategorieEquipement'/>
        },
        {
          label: <NavLink to={SUPER_ADMIN_EQUIPEMENT} title='Equipements hébergements'>Equipements</NavLink>,
          key: 'equipement_hebergement',
          icon:<FaTools className='equipement'/>
        }
      ]
    },
    {
      label: <NavLink to={SUPER_ADMIN_SERVICE} title='Catégories services hébergements'>Services hébergements</NavLink>,
      key: 'categorie_service',
      icon:<FaServicestack className='CategorieService'/>,
      children : [
        {
          label: <NavLink to={SUPER_ADMIN_CATEGORIE_SERVICE} title='Catégories services hébergements'>Catégories</NavLink>,
          key: 'categorie_service',
          icon:<FaServicestack className='CategorieService'/>
        }
        ,
        {
          label: <NavLink to={SUPER_ADMIN_SERVICE} title='Services hébergements'>Services</NavLink>,
          key: 'service_hebergement',
          icon:<FaServicestack className='equipement'/>
        }
      ]
    }
    ,
    {
      label: <NavLink to={SUPER_ADMIN_ACCESSIBILITE} title='Accessibilité'>Accessibilité</NavLink>,
      key: 'accessibilites',
      icon:<FaBus className='accessibilites'/>
    }
  );

const navigations = [
   
    {
        label: <NavLink to={SUPER_ADMIN_LIST_DOSSIER}>Dossiers</NavLink>,
        key: 'dossier',
        icon: <FaFolder className='Dossier' />
    },
    {
        label: <NavLink to={SUPER_ADMIN_LIST_HOTEL}>Hôtels</NavLink>,
        key: 'hotels',
        icon:<FaHotel className='Hotel'/>,
        children : menusHotel,
        group:"group"
    },
    {
        label: <NavLink to={SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT}>Chambres</NavLink>,
        key: 'chambre_equipement',
        icon:<FaHotel className='Chambre'/>,
        children : [
           {
            label: <NavLink to={SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT} title='Type Hébergements'>Type hébergements</NavLink>,
            key: 'type_hebergement_chambre',
            icon:<FaHotel className='type_hebergement_chambre'/>
           },
           {
              label: <NavLink to={SUPER_ADMIN_CHAMBRE_EQUIPEMENT} title='Equipements'>Equipements</NavLink>,
              key: 'categorie_equipement_chambre',
              icon:<FaTools className='categorie_equipement_chambre'/>,
              children : [
                
                {
                  label: <NavLink to={SUPER_ADMIN_CHAMBRE_CATEGORIE_EQUIPEMENT} title='Catégories équipements'>Catégories</NavLink>,
                  key: 'categorie_equipement_chambre',
                  icon:<FaTools className='categorie_equipement_chambre'/>
                },
                {
                  label: <NavLink to={SUPER_ADMIN_CHAMBRE_EQUIPEMENT} title='Equipements'>Equipements</NavLink>,
                  key: 'equipement_chambre',
                  icon:<FaTools className='equipement'/>
                }
              ]
            },
            
            {
              label: <NavLink to={SUPER_ADMIN_CHAMBRE_SERVICE} title='Services'>Services</NavLink>,
              key: 'categorie_service_chambre',
              icon:<FaServicestack className='service'/>,
              children : [
                {
                  label: <NavLink to={SUPER_ADMIN_CHAMBRE_CATEGORIE_SERVICE} title='Catégories services'>Catégories</NavLink>,
                  key: 'categorie_service_chambre',
                  icon:<FaServicestack className='service'/>
                },
                {
                  label: <NavLink to={SUPER_ADMIN_CHAMBRE_SERVICE} title='Services'>Services</NavLink>,
                  key: 'service_chambre',
                  icon:<FaServicestack className='equipement'/>
                }
              ]
            }
            
          ],
          group:"group"
    }, 
    {
        label: <NavLink to={SUPER_ADMIN_RESERVATIONS}>Réservations</NavLink>,
        key: 'reservations',
        icon:<FaBookmark className='Bookmark'/>
    },
    {
        label: <NavLink to={SUPER_ADMIN_LIST_USER}>Utilisateurs</NavLink>,
        key: 'user',
        icon:<FaUser className='user'/>
    },
    {
      label: <NavLink to={SUPER_ADMIN_VILLE}>Villes</NavLink>,
      key: 'ville',
      icon:<FaCity className='ville'/>
    }
  ];
  if(user.roles.includes('ROLE_SUPER_ADMIN')) {
   
    navigations.push(  
       
      {
          label: <NavLink to={SUPER_ADMIN_DEVISE}>Devises</NavLink>,
          key: 'devise',
          icon:<FaMoneyCheck className='devise'/>
      },
      {
          label: <NavLink to={SUPER_ADMIN_PARAMETRE}>Paramètres</NavLink>,
          key: 'parametre',
          icon:<FaTools className='parametre'/>
      },  
      {
            label: <NavLink to={SUPER_ADMIN_TRACE_UTILISATION}>Traces d'utilisation</NavLink>,
            key: 'trace_utilisation',
            icon:<FaHistory className='trace_utilisation'/>
      }
    );
  }

  return (
      <>
        <Menu
            defaultOpenKeys={['dossier']}
            defaultSelectedKeys={['dossier']}
            mode="inline"
            items={navigations}
        />
      </>
  );
}