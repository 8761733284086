import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import fr_FR from 'antd/locale/fr_FR';
import { SharedDataProvider } from 'context/SharedDataProvider';

import TagManager from 'react-gtm-module';
const tagManagerArgs = { gtmId: 'G-2QFPBERYNT' }; // Remplacez par votre ID GTM
TagManager.initialize(tagManagerArgs);

const App = () => (
  <ThemeProvider theme={theme}>
     <ConfigProvider locale={fr_FR}>
      <React.Fragment>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            <SharedDataProvider>
              <AppRoutes />
            </SharedDataProvider>
          </AuthProvider>
        </BrowserRouter>
      </React.Fragment>
    </ConfigProvider>
  </ThemeProvider>
);
const root = createRoot(document.getElementById('root'));
root.render(<App />);
